import React, { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../../../api/consts';
import { useGetPreviewMutation } from '../../../../../api/endpoints/preview-endpoint';
import { ReactComponent as OpenIcon } from '../../../../../assets/icons/open-in-new.svg';
import { useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { useGoToPage } from '../../../../../redux/hooks/go-to-page';
import { ITrackEventOrigin } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import styles from '../Actions.module.scss';

export const OpenAction: React.FC<IAnswerActionsProps> = ({ answer }) => {
    const { t } = useTranslation('translations');
    const { visible: previewModalVisible } = useAppSelector((state) => state.modals.preview);
    const [, { isLoading }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const goToPage = useGoToPage();

    const handleButtonClick: MouseEventHandler = useCallback(
        (e) => {
            e.stopPropagation();
            if (isLoading) {
                return;
            }

            goToPage({
                answerId: answer.doc_id,
                shortcutUsed: false,
                origin: previewModalVisible ? ITrackEventOrigin.PREVIEW_WINDOW : ITrackEventOrigin.ANSWERS_LIST,
            });
        },
        [answer.doc_id, isLoading, goToPage, previewModalVisible]
    );

    return (
        <Tooltip label={t('button.go-to')} placement="top-end" hasArrow>
            <OpenIcon className={isLoading ? styles.disabled : styles.action} onClick={handleButtonClick} />
        </Tooltip>
    );
};
