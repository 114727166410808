import React from 'react';
import ReactSelect, { Props } from 'react-select';

import { classNames, getStyles } from './custom-styles';
import { CustomOption } from './SelectOption';
import { SingleValueContainer } from './SingleValueContainer';
import { AccessLevelSelectOption } from './types';

type CustomSelectProps = Props<AccessLevelSelectOption, false>;

type AccessLevelSelectProps = Omit<CustomSelectProps, 'onChange'> & {
    isInvalid?: boolean;
    onChange: (value: AccessLevelSelectOption) => void;
};

export const AccessLevelSelect: React.FC<AccessLevelSelectProps> = ({
    value,
    options,
    name,
    id,
    onChange,
    isInvalid,
    isDisabled,
}) => {
    const handleChange: CustomSelectProps['onChange'] = (newValue) => {
        if (!newValue) {
            return;
        }

        onChange(newValue);
    };

    return (
        <ReactSelect
            aria-invalid={isInvalid}
            menuPlacement="auto"
            id={id}
            name={name}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            isDisabled={isDisabled}
            value={value}
            options={options}
            styles={getStyles}
            classNames={classNames}
            components={{ SingleValue: SingleValueContainer, Option: CustomOption, IndicatorSeparator: () => null }}
            onChange={handleChange}
        />
    );
};
