import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { FastField, FieldProps, getIn } from 'formik';

import { AccessLevelSelect } from './components/AccessLevelSelect';
import { AccessLevelSelectOption } from './components/types';
import { accessLevelOptions } from './access-level-options';

export const AccessLevel: React.FC = () => {
    const { t } = useTranslation('translations');

    return (
        <FastField name="accessLevel">
            {({ field, form }: FieldProps<AccessLevelSelectOption>) => {
                const isInvalid = getIn(form.errors, field.name) && getIn(form.touched, field.name);

                return (
                    <FormControl isInvalid={isInvalid}>
                        <FormLabel variant="add-knowledge">{t('ask-ai-kb.access-level')}</FormLabel>
                        <AccessLevelSelect
                            value={field.value}
                            isInvalid={isInvalid}
                            isDisabled={form.isSubmitting}
                            options={accessLevelOptions}
                            onChange={(value) => {
                                form.setFieldValue(field.name, value);
                            }}
                        />
                    </FormControl>
                );
            }}
        </FastField>
    );
};
