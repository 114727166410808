import React from 'react';
import { GroupBase, SingleValueProps } from 'react-select';

import { AccessLevelSelectOption } from './types';

type SingleValueContainerProps<T> = SingleValueProps<T, false, GroupBase<T>>;

export const SingleValueContainer: React.ComponentType<SingleValueContainerProps<AccessLevelSelectOption>> = (
    props
) => {
    const {
        data: { label, icon: Icon },
    } = props;

    return (
        <>
            <Icon />
            {label}
        </>
    );
};
