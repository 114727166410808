import { batch } from 'react-redux';

import { IDirectAnswerResult } from '../../api/types';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { AppThunk } from '../core-store';
import { _closeAskAiAddKnowledgeModal, _openAskAiAddKnowledgeModal } from '../slices/modals/ask-ai-kb-modal-slice';

import { sendMetrics } from './metrics-thunk';

export const closeAskAiAddKnowledgeModal = (): AppThunk => (dispatch) => {
    batch(() => {
        dispatch(_closeAskAiAddKnowledgeModal());
        dispatch(sendMetrics({ event: MixpanelEvent.ASK_AI_KB_CLOSE_ADD_KNOWLEDGE_MODAL }));
    });
};

export const openAskAiAddKnowledgeModal =
    (directAnswer: IDirectAnswerResult): AppThunk =>
    (dispatch) => {
        batch(() => {
            dispatch(_openAskAiAddKnowledgeModal(directAnswer));
            dispatch(sendMetrics({ event: MixpanelEvent.ASK_AI_KB_OPEN_ADD_KNOWLEDGE_MODAL }));
        });
    };
