import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Button, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';

import {
    useAddKnowledgeMutation,
    useUpdateKnowledgeMutation,
} from '../../../api/endpoints/ask-ai-kb/ask-ai-kb-endpoint';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { openSuccessModal } from '../../../redux/slices/modals/success-modal-slice';
import { closeAskAiAddKnowledgeModal } from '../../../redux/thunks/ask-ai-kb-thunk';
import { ModalContainer } from '../../Modals/Container/ModalContainer';
import { AddKnowledgeConfirmModal } from '../AddKnowledgeConfirmModal/AddKnowledgeConfirmModal';
import { accessLevelOptions } from '../AddKnowledgeForm/AccessLevel/access-level-options';
import { AccessLevelSelectOption } from '../AddKnowledgeForm/AccessLevel/components/types';
import { AddKnowledgeForm } from '../AddKnowledgeForm/AddKnowledgeForm';

import { validationSchema } from './validationSchema';

import styles from './AddKnowledgeModal.module.scss';

export interface AddKnowledgeFormValues {
    title: string;
    answer: string;
    accessLevel: AccessLevelSelectOption;
}

export const AddKnowledgeModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const isOpen = useAppSelector((state) => state.modals.askAiKb.visible);
    const directAnswer = useAppSelector((state) => state.modals.askAiKb.directAnswer);
    const question = useAppSelector((state) => state.question.question);
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const confirmPromiseRef = useRef<{ resolve: () => void; reject: () => void } | null>(null);
    const onClose = () => {
        dispatch(closeAskAiAddKnowledgeModal());
    };

    const [addKnowledge, { isLoading: isAddingKnowledge }] = useAddKnowledgeMutation();
    const [updateKnowledge, { isLoading: isUpdatingKnowledge }] = useUpdateKnowledgeMutation();
    // TODO: decide which request to use based on the condition
    const condition = true;
    const request = condition ? addKnowledge : updateKnowledge;

    const isKnowledgeProcessing = isAddingKnowledge || isUpdatingKnowledge;

    const showSuccessModal = React.useCallback(() => {
        const successMessage = {
            header: t('ask-ai-kb.add-to-kb'),
            message: t('ask-ai-kb.submitting-knowledge-success.title'),
            description: (
                <span style={{ textAlign: 'center', display: 'inline-block' }}>
                    {t('ask-ai-kb.submitting-knowledge-success.description')}
                </span>
            ),
        };

        batch(() => {
            dispatch(closeAskAiAddKnowledgeModal());
            dispatch(openSuccessModal(successMessage));
        });
    }, [dispatch, t]);

    const handleSubmit = React.useCallback(
        async (values: AddKnowledgeFormValues, formik: FormikHelpers<AddKnowledgeFormValues>) => {
            // open confirm modal and wait for user action
            setConfirmOpen(true);
            const confirm = new Promise<void>((resolve, reject) => {
                confirmPromiseRef.current = { resolve, reject };
            });

            try {
                await confirm;

                // TODO: uncomment when API is ready, and don't forget to update dependency array
                // const payload = { ...values };
                //
                // await request(payload);
                showSuccessModal();
            } catch {
                // user canceled or error occurred
            } finally {
                setConfirmOpen(false);
                // cleanup
                confirmPromiseRef.current = null;
                // should fix issue with the rich-text editor
                formik.resetForm({ values });
            }
        },
        [showSuccessModal]
    );

    const initialValues: AddKnowledgeFormValues = {
        title: question,
        answer: directAnswer?.answer ?? '',
        accessLevel: accessLevelOptions[0],
    };

    const renderFormContent = () => {
        return (
            <ModalContent>
                <Form className={styles.wrapper}>
                    <div className={styles.inner}>
                        <AddKnowledgeForm />
                    </div>
                    <ModalFooter className={styles.controls}>
                        <Button variant="secondary" onClick={onClose} isDisabled={isKnowledgeProcessing}>
                            {t('button.cancel')}
                        </Button>
                        <Button variant="primary" type="submit" isLoading={isKnowledgeProcessing}>
                            {t('ask-ai-kb.add-to-kb')}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalContent>
        );
    };

    const renderConfirmContent = () => {
        const handleConfirmClick = () => {
            confirmPromiseRef?.current?.resolve();
        };

        const handleRefuseClick = () => {
            setConfirmOpen(false);
            confirmPromiseRef?.current?.reject();
        };

        return (
            <ModalContent className={styles.confirmContainer}>
                <div className={styles.wrapper}>
                    <div className={styles.inner}>
                        <AddKnowledgeConfirmModal />
                    </div>
                    <ModalFooter className={styles.controls}>
                        <Button variant="secondary" onClick={handleRefuseClick} isDisabled={isKnowledgeProcessing}>
                            {t('button.back')}
                        </Button>
                        <Button variant="primary" onClick={handleConfirmClick} isLoading={isKnowledgeProcessing}>
                            {t('button.confirm')}
                        </Button>
                    </ModalFooter>
                </div>
            </ModalContent>
        );
    };

    const modalVariant = isConfirmOpen ? 'confirm' : 'add-knowledge';
    const content = isConfirmOpen ? renderConfirmContent() : renderFormContent();

    return (
        <ModalContainer
            variant={modalVariant}
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="scale"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                {content}
            </Formik>
        </ModalContainer>
    );
};
