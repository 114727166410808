import { StylesConfig } from 'react-select';

import { AccessLevelSelectOption } from './types';

import styles from './AccessLevelSelect.module.scss';

export const classNames = {
    menu: () => styles.menu,
    menuList: () => styles.menuList,
    control: () => styles.control,
    container: () => styles.container,
    indicatorsContainer: () => styles.indicatorsContainer,
    valueContainer: () => styles.valueContainer,
};

export const getStyles: StylesConfig<AccessLevelSelectOption, false> = {
    dropdownIndicator: (base, { isFocused, selectProps }) => {
        const rotate = isFocused && selectProps.menuIsOpen ? '180deg' : '0deg';
        return { ...base, transform: `rotate(${rotate})`, transition: 'all 0.1s ease-in' };
    },
};
