import React from 'react';
import { GroupBase, OptionProps } from 'react-select';

import { AccessLevelSelectOption } from './types';

import styles from './AccessLevelSelect.module.scss';

type CustomOptionProps<T> = OptionProps<T, false, GroupBase<T>>;

export const CustomOption: React.ComponentType<CustomOptionProps<AccessLevelSelectOption>> = (props) => {
    const {
        innerProps,
        getValue,
        hasValue,
        data: { value, label, icon: Icon },
    } = props;

    const selected = hasValue ? getValue() : null;

    // selected option is removed from render
    if (selected?.length && selected.some((sel) => sel.value === value)) {
        return null;
    }

    return (
        <div {...innerProps} className={styles.option}>
            <Icon />
            {label}
        </div>
    );
};
