import { marked } from 'marked';
import TurndownService from 'turndown';

export const markdownToHtml = (markdownText: string): string => {
    return marked.parse(markdownText) as string;
};

export function htmlToMarkdown(htmlText: string) {
    const converter = new TurndownService();
    // Quill uses OL element for both bullet list and ordered list
    // https://quilljs.com/guides/upgrading-to-2-0
    converter.addRule('dataListBullet', {
        filter: function (node, options) {
            return (
                node.nodeName === 'LI' &&
                node.parentNode?.nodeName === 'OL' &&
                node.getAttribute('data-list') === 'bullet'
            );
        },
        replacement: function (content, node, options) {
            return options.bulletListMarker + ' ' + content + '\n';
        },
    });
    converter.addRule('underline', {
        filter: ['u'],
        replacement: function (content) {
            return '<u>' + content + '</u>';
        },
    });
    converter.addRule('newline', {
        filter: ['br'],
        replacement: function () {
            return '<br>';
        },
    });
    return converter.turndown(htmlText);
}
