import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDirectAnswerResult } from '../../../api/types';

export interface IAskAiKbModalSliceState {
    visible: boolean;
    directAnswer: IDirectAnswerResult | null;
}

const initialState: IAskAiKbModalSliceState = {
    visible: false,
    directAnswer: null,
};

export const askAiKbModalSlice = createSlice({
    name: 'ask-ai-kb-modal',
    initialState,
    reducers: {
        _openAskAiAddKnowledgeModal(state, action: PayloadAction<IDirectAnswerResult>) {
            state.visible = true;
            state.directAnswer = action.payload;
        },
        _closeAskAiAddKnowledgeModal(state) {
            state.visible = false;
            state.directAnswer = null;
        },
    },
});

// we have a thunk with send metrics in it for this
export const { _closeAskAiAddKnowledgeModal, _openAskAiAddKnowledgeModal } = askAiKbModalSlice.actions;
