import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import packageJson from '../../../../package.json';
import i18n from '../../../i18n';
import { getToastService } from '../../../services/toast/toast-service';
import { baseApi } from '../../base-api';
import { ResponseStatus } from '../../types';

import { API_ASK_AI_KB_URL } from './consts';
import { IAddKnowledgeApi, IAddKnowledgeResponseApi, IUpdateKnowledgeApi, IUpdateKnowledgeResponseApi } from './types';

const clientVersion = packageJson.version;

export const askAiKbEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addKnowledge: builder.mutation<undefined, IAddKnowledgeApi>({
            queryFn: async (arg, { dispatch, getState }, _, baseQuery) => {
                const body = {
                    client_version: clientVersion,
                };

                const queryResponse = await baseQuery({
                    url: `${API_ASK_AI_KB_URL}/v1/knowledge`,
                    method: 'POST',
                    body: body,
                });

                const { data, error, meta } = queryResponse as QueryReturnValue<
                    IAddKnowledgeResponseApi,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.ask-ai-kb.add', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                return { data: undefined };
            },
        }),
        updateKnowledge: builder.mutation<undefined, IUpdateKnowledgeApi>({
            queryFn: async (arg, { dispatch, getState }, extraOptions, baseQuery) => {
                const body = {
                    client_version: clientVersion,
                };

                const answerId = '';

                const queryResponse = await baseQuery({
                    url: `${API_ASK_AI_KB_URL}/v1/knowledge/${answerId}`,
                    method: 'PUT',
                    body: body,
                });

                const { data, error, meta } = queryResponse as QueryReturnValue<
                    IUpdateKnowledgeResponseApi,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.ask-ai-kb.update', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                return { data: undefined };
            },
        }),
    }),
});

export const { useAddKnowledgeMutation, useUpdateKnowledgeMutation } = askAiKbEndpoint;
