import React from 'react';

import { AccessLevel } from './AccessLevel/AccessLevel';
import { AnswerBody } from './AnswerBody/AnswerBody';
import { Title } from './Title/Title';

import styles from './AddKnowledgeForm.module.scss';

export const AddKnowledgeForm: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.textField}>
                <Title />
            </div>
            <div className={styles.richTextEditor}>
                <AnswerBody />
            </div>
            <div className={styles.accessLevel}>
                <AccessLevel />
            </div>
        </div>
    );
};
